<template>
  <div class="login login-with-news-feed">
    <div class="news-feed">
      <div
        class="news-image"
        :style="{
          backgroundImage: 'url(' + require('@/assets/img/bg-login.jpg') + ')',
        }"
      ></div>
    </div>
    <div class="right-content">
      <div class="login-header">
        <div class="brand text-center">
          <!-- <img
            src="../assets/img/logo-square.png"
            alt="Logo"
            style="width: 40%"
          /> -->
        </div>
      </div>
      <div class="login-content">
        <h1>Reset Password</h1>
        <p>
          Silakan masukan Email anda agar kami dapat mengirimkan kode untuk
          mereset password Anda.
        </p>
        <form @submit.prevent="login" method="POST" class="margin-bottom-0">
          <div class="form-group m-b-15">
            <input
              type="text"
              class="form-control form-control-lg"
              placeholder="Email"
              id="username"
              v-model="username"
              required
            />
          </div>

          <b-alert v-if="alert == 'error'" variant="danger" show fade>
            <strong>Reset Password Gagal!</strong>
            <br />
            <span>{{ message }}</span>
          </b-alert>
          <b-alert v-if="alert == 'success'" variant="success" show fade>
            <strong>Reset Password Success!</strong>
            <br />
            <span>{{ message }}</span>
          </b-alert>
          <div class="login-buttons">
            <button type="submit" class="btn btn-success btn-block btn-lg">
              <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
              Reset Password
            </button>
          </div>
          <hr />
          <p class="text-center text-grey-darker">
            &copy; Teman DIA All Right Reserved 2024
          </p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import PageOptions from "../config/PageOptions.vue";
import axios from "axios";

export default {
  data() {
    return {
      username: "",
      password: "",
      loading: false,
      message: "",
      alert: "",
      remember_me: false,
    };
  },
  created() {
    PageOptions.pageEmpty = true;
    document.body.className = "bg-white";
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageEmpty = false;
    document.body.className = "";
    next();
  },
  methods: {
    login() {
      this.loading = true;
      const formData = new FormData();
      formData.append("username", this.username);

      axios
        .post("/api/auth/reset/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })

        .then((response) => {
          if (response.data.status == true) {
            this.loading = false;
            this.alert = "success";
            this.message = "Silahkan cek email anda, atau cek email spam";
            setTimeout(() => {
              this.$router.push("/login");
            }, 5000);
          } else {
            this.loading = false;
            this.alert = "error";
            this.message = response.data.message;
            setTimeout(() => {
              this.alert = "";
            }, 2000);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>