<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel panel-primary">
          <div class="panel-heading">
            <h3 class="panel-title bold">Form</h3>
          </div>
          <form
            @submit.prevent="postData"
            id="formPengumuman"
            enctype="multipart/form-data"
          >
            <div class="panel-body">
              <div class="row">
                <input
                  v-model="form.id_pengumuman"
                  type="text"
                  hidden
                  name="id_pengumuman"
                  class="form-control m-b-5"
                />

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="judul_pengumuman">Judul Pengumuman</label>
                    <input
                      v-model="form.judul_pengumuman"
                      type="text"
                      name="judul_pengumuman"
                      class="form-control m-b-5"
                      :class="{
                        'is-invalid':
                          formValidate.judul_pengumuman &&
                          !form.judul_pengumuman,
                      }"
                    />
                    <div
                      class="text-danger"
                      v-if="
                        formValidate.judul_pengumuman && !form.judul_pengumuman
                      "
                      v-html="formValidate.judul_pengumuman"
                    ></div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="isi_pengumuman">Isi Pengumuman</label>
                    <quill-editor
                      v-model="form.isi_pengumuman"
                      :options="editorOption"
                      class="m-b-5"
                      :style="editorStyles"
                      :class="{
                        editorError:
                          formValidate.isi_pengumuman && !form.isi_pengumuman,
                      }"
                    ></quill-editor>
                    <div
                      class="text-danger"
                      v-if="formValidate.isi_pengumuman && !form.isi_pengumuman"
                      v-html="formValidate.isi_pengumuman"
                    ></div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="kategori_target">Target</label>
                    <select
                      v-model="form.kategori_target"
                      name="kategori_target"
                      class="form-control m-b-5"
                      v-on:change="resetTarget()"
                    >
                      <option value="All">Semua</option>
                      <option value="Daerah">Daerah</option>
                      <option value="Suku">Suku</option>
                      <option value="Pekerjaan">Pekerjaan</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-3" v-if="form.kategori_target == 'Daerah'">
                  <div class="form-group">
                    <label for="kabupaten">Kabupaten</label>
                    <select
                      v-model="form.kabupaten"
                      name="kabupaten"
                      class="form-control m-b-5"
                      v-on:change="getKecamatan($event)"
                    >
                      <option value="All">Semua Kabupaten</option>
                      <option
                        v-for="kabupaten in opsiKabupaten"
                        :key="kabupaten.nama_kabupaten"
                        :value="kabupaten.nama_kabupaten"
                      >
                        {{ kabupaten.nama_kabupaten }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3" v-if="form.kategori_target == 'Daerah'">
                  <div class="form-group">
                    <label for="kecamatan">Kecamatan</label>
                    <select
                      v-model="form.kecamatan"
                      name="kecamatan"
                      class="form-control m-b-5"
                    >
                      <option value="All">Semua Kecamatan</option>
                      <option
                        v-for="kecamatan in opsiKecamatan"
                        :key="kecamatan.nama_kecamatan"
                        :value="kecamatan.nama_kecamatan"
                      >
                        {{ kecamatan.nama_kecamatan }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-md-3" v-if="form.kategori_target == 'Suku'">
                  <div class="form-group">
                    <label for="suku">Suku</label>
                    <select
                      v-model="form.suku"
                      name="suku"
                      class="form-control m-b-5"
                    >
                      <option
                        v-for="suku in opsiSuku"
                        :key="suku.nama_suku"
                        :value="suku.nama_suku"
                      >
                        {{ suku.nama_suku }}
                      </option>
                      <option value="Lain-Lain">Lain-Lain</option>
                    </select>
                  </div>
                </div>

                <div
                  class="col-md-3"
                  v-if="form.kategori_target == 'Pekerjaan'"
                >
                  <div class="form-group">
                    <label for="pekerjaan">Pekerjaan</label>
                    <select
                      v-model="form.pekerjaan"
                      name="pekerjaan"
                      class="form-control m-b-5"
                    >
                      <option value="Pelajar">Pelajar/Mahasiswa</option>
                      <option value="Karyawan Swasta">Karyawan Swasta</option>
                      <option value="PNS">PNS</option>
                      <option value="Nelayan">Nelayan/Petani</option>
                      <option value="Lain-Lain">Lain-Lain</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-12">
                  <div v-if="this.loading">
                    <div class="progress rounded-corner m-b-15">
                      <div
                        class="progress-bar bg-success progress-bar-striped progress-bar-animated"
                        :style="{ width: `${progressValue}%` }"
                      >
                        Mengirim Data
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="panel-footer">
              <button
                type="button"
                class="btn btn-success mt-4"
                @click="postData()"
                :disabled="this.loading"
              >
                <i v-if="this.loading" class="fas fa-circle-notch fa-spin"></i>
                {{ $route.params.id ? "Update" : "Submit" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.editorError {
  border: 0.5px solid #ff5b57;
}
</style>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      editorStyles: {
        // border: "1px solid red",
        // height: "200px",
        // marginBottom: "80px",
      },
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["link"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["blockquote"],
            [{ header: 1 }, { header: 2 }],
            [{ align: [] }],
            ["clean"],
          ],
        },
      },
      form: {
        id_pengumuman: "",
        judul_pengumuman: "",
        isi_pengumuman: "",
        kategori_target: "All",
        target: "All",
        kabupaten: "",
        kecamatan: "",
        suku: "",
        pekerjaan: "",
      },
      file: "",
      link: "/api/pengumuman/create",
      formValidate: [],
      opsiSuku: [],
      opsiKabupaten: [],
      opsiKecamatan: [],
      loading: false,
      message: "",
      rows: [],
      progressValue: 0,
    };
  },
  mounted() {
    this.getKabupaten();
    this.getSuku();
    const id = this.$route.params.id;
    if (id) {
      this.form.id_pengumuman = id;
      this.link = "/api/pengumuman/update";
      this.fetchData();
    } else {
      this.form.id_pengumuman = "";
    }
  },
  methods: {
    updateData(index, data) {
      // console.log(data.no_handphone);
      // console.log(data.email_pendukung);
      // console.log(this.form.isi_pengumuman);
      const formData = new FormData();
      formData.append("nama", data.nama_lengkap);
      formData.append("email", data.email_pendukung);
      formData.append("no_hp", data.no_handphone);
      formData.append("pengumuman", this.form.isi_pengumuman);
      // return true;
      return axios
        .post("/api/pengumuman/notif", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    },
    async processUpdate(index) {
      if (index >= this.dataResponse.length) {
        return; // Base case: perulangan selesai
      }

      try {
        await this.updateData(index, this.dataResponse[index]);
        // const data = await this.updateData(index, this.dataResponse[index]);
        // console.log(data);
        // Proses data yang diperoleh dari response
        // Misalnya, simpan data ke variabel atau lakukan operasi lain
        this.progressValue = ((index + 1) / this.dataResponse.length) * 100;
        // Panggil fungsi processUpdate dengan index selanjutnya
        await this.processUpdate(index + 1);
      } catch (error) {
        // Jika ada error, tampilkan di konsol dan hentikan perulangan
        console.log(error);
      }
    },
    async postData() {
      this.loading = true;
      if (this.form.pekerjaan != "") {
        this.form.target = this.form.pekerjaan;
      }
      if (this.form.suku != "") {
        this.form.target = this.form.suku;
      }
      if (this.form.kabupaten != "") {
        this.form.target = this.form.kabupaten;
      }
      if (this.form.kecamatan != "") {
        if (this.form.kecamatan == "All") {
          this.form.target = this.form.kabupaten;
        } else {
          this.form.target = this.form.kecamatan;
        }
      }
      const formData = new FormData();
      formData.append("id_pengumuman", this.form.id_pengumuman);
      formData.append("judul_pengumuman", this.form.judul_pengumuman);
      formData.append("isi_pengumuman", this.form.isi_pengumuman);
      formData.append("kategori_target", this.form.kategori_target);
      formData.append("target", this.form.target);
      if (this.form.target == "All") {
        axios
          .post(this.link, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            if (response.data.validasi == false) {
              this.loading = false;
              this.formValidate = response.data.message;
            } else {
              this.loading = false;
              Swal.fire({
                title: response.data.title,
                text: response.data.message,
                icon: response.data.icon,
                showCancelButton: false,
                showConfirmButton: false,
              });
              setTimeout(() => {
                Swal.close();
                this.$router.push("/pengumuman");
              }, 700);
            }
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      } else {
        try {
          const response = await axios.post(
            "/api/pengumuman/target",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (response.data.status == false) {
            this.loading = false;
            this.message = response.data.message;
          } else {
            this.dataResponse = response.data.data;
            // await this.processUpdate(0); // Memulai proses perulangan dari index 0

            axios
              .post(this.link, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((response) => {
                if (response.data.validasi == false) {
                  this.loading = false;
                  this.formValidate = response.data.message;
                } else {
                  this.loading = false;
                  Swal.fire({
                    title: response.data.title,
                    text: response.data.message,
                    icon: response.data.icon,
                    showCancelButton: false,
                    showConfirmButton: false,
                  });
                  setTimeout(() => {
                    Swal.close();
                    this.$router.push("/pengumuman");
                  }, 700);
                }
              })
              .catch((error) => {
                console.log(error);
                this.error = error.message;
              });

            // this.loading = false; // Set isProcessing false setelah seluruh perulangan selesai

            // // Seluruh perulangan telah selesai, lakukan tindakan lanjutan di sini
            // console.log("Seluruh perulangan selesai!");
          }
        } catch (error) {
          console.log(error);
          this.loading = false;
          this.isProcessing = false; // Set isProcessing false jika ada error
          this.error = error.message;
        }
      }
    },
    fetchData() {
      axios
        .get("/api/pengumuman/detail/" + this.form.id_pengumuman)
        .then((response) => {
          this.form.id_pengumuman = response.data.data.id_pengumuman;
          this.form.judul_pengumuman = response.data.data.judul_pengumuman;
          this.form.isi_pengumuman = response.data.data.isi_pengumuman;
          this.form.kabupaten = response.data.data.target_kabupaten;
          this.form.kecamatan = response.data.data.target_kecamatan;
          this.form.suku = response.data.data.target_suku;
          this.form.pekerjaan = response.data.data.target_pekerjaan;
          this.form.kategori_target = response.data.data.kategori_target;
          this.form.target = response.data.data.target;
          this.getOption();
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getSuku() {
      axios
        .get("/api/suku")
        .then((response) => {
          this.opsiSuku = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKabupaten() {
      axios
        .get("/api/daerah/kabupaten")
        .then((response) => {
          this.opsiKabupaten = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKecamatan(event) {
      this.form.kabupaten = event.target.value;
      axios
        .get("/api/daerah/kecamatan/" + event.target.value)
        .then((response) => {
          this.opsiKecamatan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getOption() {
      if (this.form.kabupaten) {
        axios
          .get("/api/daerah/kecamatan/" + this.form.kabupaten)
          .then((response) => {
            this.opsiKecamatan = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
      if (this.form.kecamatan) {
        axios
          .get("/api/daerah/kelurahan/" + this.form.kecamatan)
          .then((response) => {
            this.opsiKelurahan = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
    },
    resetTarget() {
      this.form.kabupaten = "";
      this.form.kecamatan = "";
      this.form.suku = "";
      this.form.pekerjaan = "";
    },
  },
};
</script>

