<template>
  <div>
    <h1 class="page-header">Dashboard <small></small></h1>
    <div class="row d-md-none mb-4" v-if="level == 'User'">
      <div class="col-md-12 mb-4">
        <img
          src="@/assets/img/banner.jpg"
          style="width: 100%; border-radius: 5px; overflow: hidden"
          alt="banner"
        />
      </div>
      <div class="col-md-6 col-sm-6 col-xs-6 mb-2">
        <router-link to="/profil" class="btn btn-primary btn-block">
          <span>LIHAT PROFIL ANDA</span>
        </router-link>
      </div>
      <div class="col-md-6 col-sm-6 col-xs-6 mb-2">
        <router-link to="/tiket" class="btn btn-warning btn-block">
          <span>ASPIRASI ANDA</span>
        </router-link>
      </div>
    </div>

    <div v-if="pengumumanList.length > 0" class="row">
      <div class="col-md-12">
        <div class="mb-2 text-dark">
          <b>PENGUMUMAN TERBARU</b>
        </div>
      </div>
      <div class="col-md-12">
        <b-alert
          v-for="data in pengumumanList"
          :key="data.kode_pengumuman"
          :value="data.kode_pengumuman"
          variant="info"
          show
          fade
        >
          <strong>{{ data.judul_pengumuman }}</strong>
          <br />
          <!-- <p v-html="data.isi_pengumuman"></p> -->
          <router-link
            class="btn btn-xs btn-info m-t-5"
            :to="'/pengumuman/detail/' + data.id_pengumuman"
          >
            Lihat
          </router-link>
        </b-alert>
      </div>
    </div>

    <div class="row" v-if="this.showBirthday">
      <div class="col-md-12">
        <b-alert variant="success" show fade>
          <strong>{{ this.titleBirthday }} {{ this.namaLengkap }} </strong>
          <i class="fa fa-gift"></i>
        </b-alert>
      </div>
    </div>

    <div class="row" v-if="level == 'User'">
      <div class="col-xl-6 col-md-6">
        <div class="widget widget-stats bg-blue">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-ticket-alt fa-fw"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">TIKET</div>
            <div class="stats-number">{{ this.tiketTotal }}</div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-md-6">
        <div class="widget widget-stats bg-danger">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-bullhorn fa-fw"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">PENGUMUMAN</div>
            <div class="stats-number">{{ this.pengumumanTotal }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-else>
      <div class="col-xl-4 col-md-6">
        <div class="widget widget-stats bg-teal">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-users fa-fw"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">PENDUKUNG</div>
            <div class="stats-number">{{ this.pendukungTotal }}</div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-md-6">
        <div class="widget widget-stats bg-blue">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-ticket-alt fa-fw"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">TIKET</div>
            <div class="stats-number">{{ this.tiketTotal }}</div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-md-6">
        <div class="widget widget-stats bg-danger">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-bullhorn fa-fw"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">PENGUMUMAN</div>
            <div class="stats-number">{{ this.pengumumanTotal }}</div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="level == 'User'">
      <div class="row">
        <div class="col-md-12 mb-3">
          <div ref="pieKabupaten"></div>
          <div ref="barKabupaten" hidden></div>
          <div ref="barSuku" hidden></div>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="row">
        <div class="col-md-6 mb-3">
          <div ref="barKabupaten"></div>
        </div>
        <div class="col-md-6 mb-3">
          <div ref="pieKabupaten"></div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 mb-3">
          <div ref="barSuku"></div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 mb-3">
        <div class="card border-0 mb-3 bg-dark text-white">
          <div class="card-body">
            <div class="mb-3 text-grey">
              <b>LEADERBOARD</b>
            </div>
            <div
              class="d-flex align-items-center m-b-15"
              v-for="data in leaderboardList"
              :key="data.nama_lengkap"
              :value="data.nama_lengkap"
            >
              <div
                class="widget-img rounded-lg m-r-10 bg-white p-3 width-50 height-50"
              >
                <div
                  class="h-100 w-100 background-container"
                  :style="{
                    backgroundImage: `url('${data.link_foto}')`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'auto 100%',
                  }"
                ></div>
              </div>
              <div class="text-truncate">
                <div v-if="level == 'User'">
                  {{ data.nama_lengkap }}
                </div>
                <div v-else>
                  <router-link
                    :to="'/leaderboard/detail/' + data.nomor_identitas"
                  >
                    {{ data.nama_lengkap }}
                  </router-link>
                </div>
                <div class="text-grey">{{ data.kabupaten }}</div>
              </div>
              <div class="ml-auto text-center">
                <div class="f-s-13">
                  <span data-animation="number">{{
                    data.agregat_referal
                  }}</span>
                </div>
                <div class="text-grey f-s-10">referal</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.background-container {
  position: relative;
  height: 100px; /* Ubah sesuai kebutuhan */
  width: 100%; /* Ubah sesuai kebutuhan */
}

.background-image {
  background: center no-repeat;
  background-size: auto 100%;
}

@supports (background: url("invalid-url")) {
  .background-image {
    background: red; /* Tampilan alternatif ketika gambar tidak didukung atau tidak ada */
  }
}
</style>

<script>
import axios from "axios";
import Highcharts from "highcharts";

export default {
  name: "dashboard",
  data() {
    return {
      nama_kabupaten: [],
      jumlah_pendukung: [],
      nama_suku: [],
      jumlah_suku: [],
      pendukungTotal: 0,
      tiketTotal: 0,
      pengumumanTotal: 0,
      pengumumanList: [],
      leaderboardList: [],
      username: "",
      level: "",
      nik: "",
      tgl_lahir: "",
      suku: "",
      showBirthday: false,
      titleBirthday: "",
      textBirthday: "",
      namaLengkap: "",
    };
  },
  // mounted() {
  //   this.fetchGrafik();
  // },
  // mounted() {
  //   this.checkLogin();
  // },
  created() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    const user = localStorage.getItem("user");
    if (user) {
      this.user = JSON.parse(user);
      this.level = this.user.level;
      this.nik = this.user.nik;
      this.tgl_lahir = this.user.tgl_lahir;
      this.suku = this.user.suku;
      this.namaLengkap = this.user.nama;
      if (this.tgl_lahir == formattedDate) {
        this.fetchTextBirthday();
        this.showBirthday = true;
      }
      this.fetchGrafik();
    } else {
      // this.$router.push("/login");
    }
  },
  // created() {
  //   setInterval(() => {
  //     this.fetchGrafik();
  //   }, 10000);
  // },
  methods: {
    fetchGrafik() {
      const formData = new FormData();
      formData.append("nik", this.nik);
      formData.append("level", this.level);
      axios
        .post("/api/dashboard/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.nama_kabupaten = response.data.grafik_kabupaten.map(
            (item) => item.kabupaten
          );
          this.jumlah_pendukung = response.data.grafik_kabupaten.map((item) =>
            parseInt(item.jumlah)
          );
          this.nama_suku = response.data.grafik_suku.map((item) => item.suku);
          this.jumlah_suku = response.data.grafik_suku.map((item) =>
            parseInt(item.jumlah)
          );
          this.pendukungTotal = response.data.pendukung;
          this.tiketTotal = response.data.tiket;
          this.pengumumanTotal = response.data.pengumuman;
          this.pengumumanList = response.data.pengumumanList;
          this.leaderboardList = response.data.leaderboardList;
          this.renderBarKabupaten();
          this.renderPieKabupaten();
          this.renderBarSuku();
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchTextBirthday() {
      const formData = new FormData();
      formData.append("suku", this.suku);
      axios
        .post("/api/birthdaytext/text/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.titleBirthday = response.data.title_birthday;
          this.textBirthday = response.data.text_birthday;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    renderBarKabupaten() {
      Highcharts.chart(this.$refs.barKabupaten, {
        chart: {
          type: "bar",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Jumlah Pendukung Berdasarkan Kabupaten",
        },
        xAxis: {
          categories: this.nama_kabupaten,
        },
        yAxis: {
          title: {
            text: "Jumlah",
          },
        },
        series: [
          {
            name: "Jumlah",
            data: this.jumlah_pendukung,
            colorByPoint: true,
          },
        ],
      });
    },
    renderBarSuku() {
      Highcharts.chart(this.$refs.barSuku, {
        chart: {
          type: "column",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Jumlah Pendukung Berdasarkan Suku",
        },
        xAxis: {
          categories: this.nama_suku,
        },
        yAxis: {
          title: {
            text: "Jumlah",
          },
        },
        series: [
          {
            name: "Jumlah",
            data: this.jumlah_suku,
            colorByPoint: true,
          },
        ],
      });
    },
    renderPieKabupaten() {
      Highcharts.chart(this.$refs.pieKabupaten, {
        chart: {
          type: "pie",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Persentase Pendukung Berdasarkan Kabupaten",
        },
        series: [
          {
            name: "Persentase",
            data: this.getPieDataSeries(),
            colorByPoint: true,
          },
        ],
      });
    },
    getPieDataSeries() {
      const dataSeries = [];
      const totalPendukung = this.jumlah_pendukung.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      for (let i = 0; i < this.nama_kabupaten.length; i++) {
        const persentase = (this.jumlah_pendukung[i] / totalPendukung) * 100;
        dataSeries.push({
          name: this.nama_kabupaten[i],
          y: persentase,
        });
      }
      return dataSeries;
    },

    // checkLogin() {
    //   const formData = new FormData();
    //   formData.append("username", this.username);
    //   axios
    //     .post("/api/auth/cekStatus/", formData, {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //     })
    //     .then((response) => {
    //       if (response.data.status == false) {
    //         localStorage.removeItem("user");
    //         if (this.$route.path !== "/login") {
    //           this.$router.push("/login");
    //         }
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       this.error = error.message;
    //     });
    // },
  },
};
</script>