<template>
  <div class="login login-with-news-feed">
    <div class="news-feed">
      <div
        class="news-image"
        :style="{
          backgroundImage: 'url(' + require('@/assets/img/bg-login.jpg') + ')',
        }"
      ></div>
    </div>
    <div class="right-content">
      <div class="login-header">
        <div class="brand text-center">
          <img
            src="../assets/img/logo-square.png"
            alt="Logo"
            style="width: 40%"
          />
        </div>
      </div>
      <div class="login-content">
        <form @submit.prevent="login" method="POST" class="margin-bottom-0">
          <div class="form-group m-b-15">
            <input
              type="text"
              class="form-control form-control-lg"
              placeholder="Email/Username"
              id="username"
              v-model="username"
              required
            />
          </div>
          <div class="form-group m-b-15">
            <input
              type="password"
              class="form-control form-control-lg"
              placeholder="Password"
              id="password"
              v-model="password"
              required
            />
          </div>
          <div class="row">
            <div class="col-xs-6">
              <div class="checkbox checkbox-css m-b-30">
                <input
                  type="checkbox"
                  id="remember_me"
                  name="remember_me"
                  v-model="remember_me"
                />
                <label for="remember_me"> Remember Me </label>
              </div>
            </div>
            <div class="col-xs-6">
              <div class="checkbox checkbox-css m-b-30 text-right">
                <router-link to="/lupa-password">Lupa Password</router-link>
              </div>
            </div>
          </div>

          <b-alert v-if="alert == 'error'" variant="danger" show fade>
            <strong>Login Gagal!</strong>
            <br />
            <span>{{ message }}</span>
          </b-alert>
          <b-alert v-if="alert == 'success'" variant="success" show fade>
            <strong>Login Success!</strong>
            <br />
            <span>{{ message }}</span>
          </b-alert>
          <div class="login-buttons">
            <button type="submit" class="btn btn-success btn-block btn-lg">
              <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
              LOGIN
            </button>
          </div>
          <div
            class="m-t-20 p-b-20 text-inverse text-center"
            style="font-size: 16px; font-weight: bold"
          >
            Belum terdaftar, klik tombol di bawah
          </div>
          <div class="login-buttons">
            <router-link to="/register" class="btn btn-danger btn-block btn-lg"
              >REGISTRASI</router-link
            >
          </div>
          <hr />
          <p class="text-center text-grey-darker">
            &copy; Teman DIA All Right Reserved 2024
          </p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import PageOptions from "../config/PageOptions.vue";
import axios from "axios";

export default {
  data() {
    return {
      username: "",
      password: "",
      loading: false,
      message: "",
      alert: "",
      remember_me: false,
    };
  },
  created() {
    PageOptions.pageEmpty = true;
    document.body.className = "bg-white";
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageEmpty = false;
    document.body.className = "";
    next();
  },
  methods: {
    login() {
      this.loading = true;
      const formData = new FormData();
      formData.append("username", this.username);
      formData.append("password", this.password);
      formData.append("remember", this.remember_me);

      axios
        .post("/api/auth/login/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })

        .then((response) => {
          if (response.data.status == true) {
            this.loading = false;
            this.alert = "success";
            this.message = "Selamat Datang " + response.data.nama;
            setTimeout(() => {
              const user = {
                nama: response.data.nama,
                username: response.data.email,
                foto: response.data.foto,
                nik: response.data.nik,
                level: response.data.level,
                tgl_lahir: response.data.tgl_lahir,
                suku: response.data.suku,
                no_anggota: response.data.no_anggota,
              };
              localStorage.setItem("user", JSON.stringify(user));
              if (response.data.level === "Admin") {
                this.$router.push("/dashboard");
              } else if (response.data.level === "User") {
                this.$router.push("/dashboard");
              } else {
                this.$router.push("/dashboard");
              }
            }, 2000);
          } else {
            this.loading = false;
            this.alert = "error";
            this.message = response.data.message;
            setTimeout(() => {
              this.alert = "";
            }, 2000);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });

      // if (this.username === "admin" && this.password === "admin") {
      //   const user = {
      //     username: this.username,
      //     level: "admin", // Ganti dengan level pengguna yang sesuai
      //   };
      //   localStorage.setItem("user", JSON.stringify(user));
      //   this.$router.push("/dashboard");
      // } else if (this.username === "user" && this.password === "user") {
      //   const user = {
      //     username: this.username,
      //     level: "user", // Ganti dengan level pengguna yang sesuai
      //   };
      //   localStorage.setItem("user", JSON.stringify(user));
      //   this.$router.push("/dashboard");
      // } else {
      //   alert("Username atau password salah");
      // }
    },
  },
};
</script>