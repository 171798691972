import axios from "axios";
import Swal from "sweetalert2";

const baseURL = process.env.VUE_APP_BASE_URL;

axios.interceptors.request.use((request) => {
  request["baseURL"] = baseURL;
  return request;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status, data } = error.response;

    if (status >= 500) {
      Swal.fire({
        icon: "error",
        title: "",
        text: data.message,
      });
    }

    return Promise.reject(error);
  }
);
