<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel panel-primary">
          <div class="panel-heading">
            <h3 class="panel-title bold">Form</h3>
          </div>
          <form
            @submit.prevent="postData"
            id="formPeserta"
            enctype="multipart/form-data"
          >
            <div class="panel-body">
              <div class="row">
                <div class="col-md-2" v-if="cropImg">
                  <div class="cropped-image">
                    <img
                      v-if="cropImg"
                      :src="cropImg"
                      alt="Cropped Image"
                      style="
                        width: 80%;
                        border-radius: 50%;
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                      "
                    />
                    <div v-else class="crop-placeholder" />
                  </div>
                </div>
                <div
                  class="col-md-10"
                  v-bind:class="cropImg ? 'col-md-10' : 'col-md-12'"
                >
                  <div class="row">
                    <input
                      v-model="form.id_pendukung"
                      type="text"
                      hidden
                      name="id_pendukung"
                      class="form-control m-b-5"
                    />
                    <input
                      v-model="form.foto_lama"
                      type="text"
                      hidden
                      name="foto_lama"
                      class="form-control m-b-5"
                    />
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="nomor_identitas"
                          >Nomor Induk Kependudukan (NIK)</label
                        >
                        <masked-input
                          v-model="form.nomor_identitas"
                          type="text"
                          name="nomor_identitas"
                          class="form-control m-b-5"
                          mask="1111111111111111"
                          :class="{
                            'is-invalid': formValidate.nomor_identitas,
                          }"
                        ></masked-input>
                        <small
                          class="text-danger"
                          v-html="formValidate.nomor_identitas"
                        ></small>
                      </div>
                    </div>

                    <div class="col-md-8">
                      <div class="form-group">
                        <label for="nama_lengkap">Nama Lengkap</label>
                        <input
                          v-model="form.nama_lengkap"
                          type="text"
                          name="nama_lengkap"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.nama_lengkap,
                          }"
                        />
                        <small
                          class="text-danger"
                          v-html="formValidate.nama_lengkap"
                        ></small>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="email_pendukung">Email</label>
                        <input
                          v-model="form.email_pendukung"
                          type="email"
                          name="email_pendukung"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.email_pendukung,
                          }"
                        />
                        <small
                          class="text-danger"
                          v-html="formValidate.email_pendukung"
                        ></small>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="no_handphone">Nomor WA</label>
                        <masked-input
                          v-model="form.no_handphone"
                          type="text"
                          name="no_handphone"
                          class="form-control m-b-5"
                          mask="1111-1111-1111"
                          :class="{
                            'is-invalid': formValidate.no_handphone,
                          }"
                        ></masked-input>
                        <small
                          class="text-danger"
                          v-html="formValidate.no_handphone"
                        ></small>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="kabupaten">Kabupaten</label>
                        <select
                          v-model="form.kabupaten"
                          name="kabupaten"
                          class="form-control m-b-5"
                          v-on:change="getKecamatan($event)"
                          :class="{
                            'is-invalid': formValidate.kabupaten,
                          }"
                        >
                          <option value="">Pilih</option>
                          <option
                            v-for="kabupaten in opsiKabupaten"
                            :key="kabupaten.nama_kabupaten"
                            :value="kabupaten.nama_kabupaten"
                          >
                            {{ kabupaten.nama_kabupaten }}
                          </option>
                        </select>
                        <small
                          class="text-danger"
                          v-html="formValidate.kabupaten"
                        ></small>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="kecamatan">Kecamatan</label>
                        <select
                          v-model="form.kecamatan"
                          name="kecamatan"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.kecamatan,
                          }"
                          v-on:change="getKelurahan($event)"
                        >
                          <option value="">Pilih</option>
                          <option
                            v-for="kecamatan in opsiKecamatan"
                            :key="kecamatan.nama_kecamatan"
                            :value="kecamatan.nama_kecamatan"
                          >
                            {{ kecamatan.nama_kecamatan }}
                          </option>
                        </select>
                        <small
                          class="text-danger"
                          v-html="formValidate.kecamatan"
                        ></small>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="kelurahan">Kelurahan</label>
                        <select
                          v-model="form.kelurahan"
                          name="kelurahan"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.kelurahan,
                          }"
                        >
                          <option value="">Pilih</option>
                          <option
                            v-for="kelurahan in opsiKelurahan"
                            :key="kelurahan.nama_kelurahan"
                            :value="kelurahan.nama_kelurahan"
                          >
                            {{ kelurahan.nama_kelurahan }}
                          </option>
                        </select>
                        <small
                          class="text-danger"
                          v-html="formValidate.kelurahan"
                        ></small>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="rw">RW</label>
                        <masked-input
                          v-model="form.rw"
                          type="text"
                          name="rw"
                          class="form-control m-b-5"
                          mask="111"
                          placeholder="Contoh 001"
                          :class="{
                            'is-invalid': formValidate.rw,
                          }"
                        ></masked-input>
                        <small
                          class="text-danger"
                          v-html="formValidate.rw"
                        ></small>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="tps">TPS</label>
                        <masked-input
                          v-model="form.tps"
                          type="text"
                          name="tps"
                          class="form-control m-b-5"
                          mask="111"
                          placeholder="Contoh 001"
                          :class="{
                            'is-invalid': formValidate.tps,
                          }"
                        ></masked-input>
                        <small
                          class="text-danger"
                          v-html="formValidate.tps"
                        ></small>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="suku">Suku</label>
                        <select
                          v-model="form.suku"
                          name="suku"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.suku,
                          }"
                        >
                          <option value="">Pilih</option>
                          <option
                            v-for="suku in opsiSuku"
                            :key="suku.nama_suku"
                            :value="suku.nama_suku"
                          >
                            {{ suku.nama_suku }}
                          </option>
                          <option value="lain-Lain">Lain-Lain</option>
                        </select>
                        <small
                          class="text-danger"
                          v-html="formValidate.suku"
                        ></small>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="tgl_lahir">Tanggal Lahir</label>
                        <input
                          type="date"
                          v-model="form.tgl_lahir"
                          class="form-control m-b-5"
                          placeholder="Tanggal Lahir"
                          :class="{
                            'is-invalid': formValidate.tgl_lahir,
                          }"
                        />
                        <small
                          class="text-danger"
                          v-html="formValidate.tgl_lahir"
                        ></small>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="pekerjaan">Pekerjaan </label>
                        <select
                          v-model="form.pekerjaan"
                          name="pekerjaan"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.pekerjaan,
                          }"
                        >
                          <option value="">Pilih</option>
                          <option value="Pelajar">Pelajar/Mahasiswa</option>
                          <option value="Karyawan Swasta">
                            Karyawan Swasta
                          </option>
                          <option value="PNS">PNS</option>
                          <option value="Nelayan">Nelayan/Petani</option>
                          <option value="Lain-Lain">Lain-Lain</option>
                        </select>
                        <small
                          class="text-danger"
                          v-html="formValidate.pekerjaan"
                        ></small>
                      </div>
                    </div>
                    <div class="col-md-2" v-if="this.showUserAccess">
                      <div class="form-group">
                        <label for="level">Level User</label>
                        <select
                          v-model="form.level"
                          name="level"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.level,
                          }"
                        >
                          <option value="">Pilih</option>
                          <option value="User">User</option>
                          <option value="Operator">Operator</option>
                          <option value="Admin">Admin</option>
                        </select>
                        <span
                          class="text-danger"
                          v-html="formValidate.level"
                        ></span>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="foto">Foto</label>
                        <div>
                          <a
                            href="#"
                            class="btn btn-primary m-1"
                            role="button"
                            @click.prevent="showFileChooser"
                          >
                            <i class="fa fa-fw fa-image"></i> Pilih/Ambil Foto
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <input
                        style="display: none"
                        ref="input"
                        type="file"
                        name="image"
                        accept="image/*"
                        @change="setImage"
                      />

                      <div class="row">
                        <div class="col-md-12 cropper-area">
                          <div class="img-cropper">
                            <vue-cropper
                              ref="cropper"
                              :aspect-ratio="1 / 1"
                              :src="imgSrc"
                              preview=".preview"
                              style="
                                width: 100%;
                                border-radius: 5px;
                                overflow: hidden;
                              "
                            />
                            <div v-if="this.imgSrc">
                              <div class="btn-group mt-1">
                                <a
                                  href="#"
                                  class="btn btn-sm btn-primary"
                                  role="button"
                                  @click.prevent="rotate(90)"
                                >
                                  <i class="fa fa-redo"></i>
                                </a>
                                <a
                                  href="#"
                                  class="btn btn-sm btn-primary"
                                  role="button"
                                  @click.prevent="rotate(-90)"
                                >
                                  <i class="fa fa-redo fa-flip-horizontal"></i>
                                </a>
                                <a
                                  href="#"
                                  class="btn btn-sm btn-primary"
                                  role="button"
                                  @click.prevent="cropImage"
                                >
                                  <i class="fa fa-crop"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="checkbox checkbox-css">
                        <input
                          type="checkbox"
                          id="tim_relawan_tps"
                          name="tim_relawan_tps"
                          v-model="form.tim_relawan_tps"
                        />
                        <label for="tim_relawan_tps"
                          >Saya bersedia mencoblos Paslon Danny Azhar dan
                          menjadi Saksi di TPS (Tempat Pemungutan Suara)</label
                        >
                      </div>
                    </div>

                    <div class="col-md-12">
                      <button
                        type="button"
                        class="btn btn-success mt-4"
                        @click="postData()"
                        :disabled="this.loading"
                      >
                        <i
                          v-if="this.loading"
                          class="fas fa-circle-notch fa-spin"
                        ></i>
                        {{ $route.params.id ? "Update" : "Submit" }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="panel-footer"></div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import MaskedInput from "vue-masked-input";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  components: {
    MaskedInput,
    VueCropper,
  },
  data() {
    return {
      form: {
        id_pendukung: "",
        no_urut: "",
        no_pendukung: "",
        nama_lengkap: "",
        nama_panggilan: "",
        no_handphone: "",
        email_pendukung: "",
        nomor_identitas: "",
        kabupaten: "",
        kecamatan: "",
        kelurahan: "",
        rw: "",
        tps: "",
        suku: "",
        level: "User",
        tim_relawan_tps: false,
        foto: "",
        foto_lama: "",
        foto_crop: "",
        tgl_lahir: "",
        pekerjaan: "",
      },
      link: "/api/pendukung/create",
      opsiSuku: [],
      opsiKabupaten: [],
      opsiKecamatan: [],
      opsiKelurahan: [],
      imgSrc: "",
      cropImg: "",
      formValidate: [],
      loading: false,
      levelUser: "",
      showUserAccess: true,
    };
  },
  mounted() {
    this.getKabupaten();
    this.getSuku();
    const id = this.$route.params.id;
    if (id) {
      this.link = "/api/pendukung/update";
      this.form.id_pendukung = id;
      this.fetchData();
    } else {
      this.form.id_pendukung = "";
    }
  },
  created() {
    const user = localStorage.getItem("user");
    if (user) {
      this.user = JSON.parse(user);
      this.levelUser = this.user.level;
      if (this.levelUser !== "Admin") {
        this.showUserAccess = false;
      }
    }
  },
  methods: {
    postData() {
      this.loading = true;
      let nik = this.form.nomor_identitas;
      this.form.nomor_identitas = nik.split("_").join("");
      let no_handphone = this.form.no_handphone;
      no_handphone = no_handphone.split("_").join("");
      this.form.no_handphone = no_handphone.split("-").join("");
      const formData = new FormData();

      if (this.cropImg.includes("http") || this.cropImg == "") {
        // formData.append("foto", "");
      } else {
        const blob = this.dataURLtoBlob(this.cropImg);
        formData.append("foto", blob, "foto.png");
      }

      formData.append("id_pendukung", this.form.id_pendukung);
      formData.append("no_urut", this.form.no_urut);
      formData.append("no_pendukung", this.form.no_pendukung);
      formData.append("nama_lengkap", this.form.nama_lengkap);
      formData.append("nama_panggilan", this.form.nama_panggilan);
      formData.append("no_handphone", this.form.no_handphone);
      formData.append("email_pendukung", this.form.email_pendukung);
      formData.append("nomor_identitas", this.form.nomor_identitas);
      formData.append("kabupaten", this.form.kabupaten);
      formData.append("kecamatan", this.form.kecamatan);
      formData.append("kelurahan", this.form.kelurahan);
      formData.append("rw", this.form.rw);
      formData.append("tps", this.form.tps);
      formData.append("suku", this.form.suku);
      formData.append("level", this.form.level);
      formData.append("tim_relawan_tps", this.form.tim_relawan_tps);
      formData.append("foto_lama", this.form.foto_lama);
      formData.append("tgl_lahir", this.form.tgl_lahir);
      formData.append("pekerjaan", this.form.pekerjaan);

      // console.log(formData.get("foto"));
      axios
        .post(this.link, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              this.$router.push("/pendukung");
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchData() {
      axios
        .get("/api/pendukung/detail/" + this.form.id_pendukung)
        .then((response) => {
          this.form.no_urut = response.data.data.no_urut;
          this.form.no_pendukung = response.data.data.no_pendukung;
          this.form.nama_lengkap = response.data.data.nama_lengkap;
          this.form.nama_panggilan = response.data.data.nama_panggilan;
          this.form.no_handphone = response.data.data.no_handphone;
          this.form.no_handphone = response.data.data.no_handphone;
          this.form.email_pendukung = response.data.data.email_pendukung;
          this.form.nomor_identitas = response.data.data.nomor_identitas;
          this.form.kabupaten = response.data.data.kabupaten;
          this.form.kecamatan = response.data.data.kecamatan;
          this.form.kecamatan = response.data.data.kecamatan;
          this.form.kelurahan = response.data.data.kelurahan;
          this.form.rw = response.data.data.rw;
          this.form.tps = response.data.data.tps;
          this.form.suku = response.data.data.suku;
          this.form.level = response.data.data.level;
          this.form.tgl_lahir = response.data.data.tgl_lahir;
          this.form.pekerjaan = response.data.data.pekerjaan;
          this.form.foto_lama = response.data.data.foto;
          this.cropImg = response.data.data.link_foto;
          if (response.data.data.tim_relawan_tps == "Ya") {
            this.form.tim_relawan_tps = true;
          } else {
            this.form.tim_relawan_tps = false;
          }
          this.getOption();
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getSuku() {
      axios
        .get("/api/suku")
        .then((response) => {
          this.opsiSuku = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKabupaten() {
      axios
        .get("/api/daerah/kabupaten")
        .then((response) => {
          this.opsiKabupaten = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKecamatan(event) {
      this.form.kabupaten = event.target.value;
      axios
        .get("/api/daerah/kecamatan/" + event.target.value)
        .then((response) => {
          this.opsiKecamatan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKelurahan(event) {
      this.form.kecamatan = event.target.value;
      axios
        .get("/api/daerah/kelurahan/" + event.target.value)
        .then((response) => {
          this.opsiKelurahan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getOption() {
      if (this.form.kabupaten) {
        axios
          .get("/api/daerah/kecamatan/" + this.form.kabupaten)
          .then((response) => {
            this.opsiKecamatan = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
      if (this.form.kecamatan) {
        axios
          .get("/api/daerah/kelurahan/" + this.form.kecamatan)
          .then((response) => {
            this.opsiKelurahan = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
    },

    dataURLtoBlob(dataURL) {
      const base64Data = dataURL.split(",")[1];
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: "image/png" });
    },

    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },

    setImage(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
  },
};
</script>
